<template>
  <div class="normal-text bg-dark-theme text-center">
    <div class="bg-1" :class="{'image-loading': !bg1ImageLoaded}">
      <div class="d-block m-0">
        <LandingPageHeader :trasparentHeader="true" />
        <div class="clearfix"></div>
      </div>
      <div v-if="!bg1ImageLoaded" class="main_loader">
        <div class="img-loader"></div>
      </div>
      <div class="h-8vh h-md-0"></div>
      <section v-if="bg1ImageLoaded" class="landing_view h-md-100vh">
        <div class="row d-flex align-items-start justify-content-end">
          <div class="col-12 col-md-10">
            <div class="row">
              <div class="col-12 mt-5">
                <h1 class="large-text fnt-tny text-uppercase text-center">
                  <div>{{ $t('MOM Provider') }}</div>
                  <div>{{ $t('AI powered booking management software') }}</div>
                </h1>
              </div>
              <div class="col-12 px-md-3">
                <p class="fs-md-1p625rem">
                  {{ $t('Discover our all-in-one ERP solution designed for efficiency and growth with seamless integration, manage your Website, Bookings, Cloud POS, HRMS, Accounting, Marketing, and Agent Management effortlessly') }}.
                </p>
              </div>
              <div class="col-12">
                <b-link class="custom-design custom-design-black px-md-3 font-weight-bold tra-black-bg" :to="providerRedirect">{{ $t('SIGN UP FOR FREE') }}</b-link>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <div>{{ $t('No credit card required!') }}</div>
              </div>
              <div class="col-12">
                <b-link href="https://play.google.com/store/apps/details?id=com.momitnow.twa" target="_blank">
                  <b-img :src="`${$apiUrl}/images/landing/google-play-store.png`" class="gplay_img" fluid alt="Google Play Store" />
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase">{{ $t('Your Gateway To Expanding Your Service Business') }}</h1>
          <h2 class="large-text-2">{{ $t('MOM Provider App equips service businesses with the essential tools and features needed to thrive in today\'s digital landscape') }}.</h2>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row">
            <div class="col-12 col-md-4 mb-1 mb-md-0">
              <div class="row d-flex justify-content-center">
                <div class="col-10">
                  <b-img :src="`${$apiUrl}/images/landing/Website/MOM Service.webp`" class="home_img" fluid />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8 text-left d-flex align-items-center">
              <div>
                <p class=""> 
                  <strong>{{$t('Effortless Booking:')}}</strong>
                  {{ $t("Simplify your service booking process with our intuitive interface") }}. {{ $t("Customers can book your services in a few clicks, thanks to our user-friendly design") }}.
                </p>
                <p class=""> 
                  <strong>{{$t('Secure Payments:')}}</strong>
                  {{ $t("Enjoy hassle-free transactions with secure payment processing, directly depositing funds into your company account") }}.
                </p>
                <p class=""> 
                  <strong>{{$t('Data-Driven Growth:')}}</strong>
                  {{ $t("Utilize our powerful analytics tools to make informed decisions") }}. {{ $t("Track performance, analyze trends, and refine your services for maximum profitability") }}.
                </p>
                <p class=""> 
                  <strong>{{$t('Agent Management:')}}</strong>
                  {{ $t("Empower your agents with the MOM Agent App, giving them their own accounts to manage schedules on the go") }}. {{ $t("This allows them to provide services at customer locations with real-time tracking and visibility") }}.
                </p>
                <p class=""> 
                  <strong>{{$t('Booking Customization:')}}</strong>
                  {{ $t("Offer your customers the flexibility to customize their bookings and upsell add-ons and other services") }}. {{ $t("Enhance your revenue by providing a personalized experience") }}.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('A full solution to manage and grow your business, in 3 easy steps') }}
          </h1>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row landing_items_view">
            <div class="col-12 col-md-4 landing_item" v-for="(item, ik) in landingItems" :key="`ik_${ik}`">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 col-md-8 mx-auto">
                      <b-img
                        fluid
                        :src="`${$apiUrl}/images/landing/provider/${item.img}`"
                        :alt="item.imgAlt"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-1">
                  <b-link class="custom-design" :to="providerRedirect">{{ $t(item.title) }}</b-link>
                  <span v-for="(itemp, ikp) in item.text" :key="`itp_${ikp}_${ik}`">
                    <p class="text-muted" v-html="itemp"></p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Solution for Every Business') }}
          </h1>
        </div>
      </div>
      <div class="row landing_grids">
        <div class="col-12 col-md-4 grid_items" v-for="(menu, index) in servicesList" :key="`l_card_${index}`">
          <div class="ai_widget_img">
            <b-img fluid :src="`${$apiUrl}/images/landing-provider/${menu.image}`" class="home_img" :alt="menu.title" />
          </div>
          <b-link class="ai_widget_label custom-design mx-auto" :to="providerRedirect">{{ $t(menu.title) }}</b-link>
        </div>
      </div>
    </section>

    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('CHOOSE MOM TO MANAGE YOUR BOOKINGS - HERE\'S WHY') }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-10 mx-auto text-center">
          <ol class="why_mom_listing" start="1">
            <li>
                {{ $t("0% Commission: Keep 100% of your earnings with MOM Provider App") }}. {{ $t("We support service providers by not taking any commission, allowing you to maximize your profits") }}.
            </li>
            <li>
                {{ $t("Analytics & Reports: Gain valuable insights with our robust analytics and reporting tools") }}. {{ $t("Track key metrics, understand customer behavior, and make data-driven decisions to optimize your services and grow your revenue") }}.
            </li>
            <li>
                {{ $t("Appointment Scheduling: Streamline your operations with our intuitive appointment scheduling feature") }}. {{ $t("Manage bookings, set availability, and provide customers with a convenient way to schedule services") }}.
            </li>
            <li>
                {{ $t("Agent Management: Efficiently assign agents to customer locations, ensuring timely service delivery") }}. {{ $t("Oversee your workforce effectively and optimize agent allocation for improved customer satisfaction") }}.
            </li>
            <li>
                {{ $t("Service at Customer Location: Offer services directly at your customer's location, enhancing convenience and accessibility") }}.
            </li>
            <li>
                {{ $t("Direct Payment to Bank: Enjoy prompt and secure payments directly to your bank account, eliminating payment delays") }}.
            </li>
            <li>
                {{ $t("Booking Dashboard: Stay organized with a user-friendly booking dashboard") }}. {{ $t("Manage appointments, track upcoming services, and access customer information all in one place") }}.
            </li>
            <li>
                {{ $t("Promotion and Loyalty Programs: Boost your business with promotion and loyalty program features") }}. {{ $t("Create enticing offers, coupons, and loyalty rewards to attract and retain customers") }}.
            </li>
            <li>
                {{ $t("Cloud POS (Point of Sale): Simplify transactions with our Cloud POS system") }}. {{ $t("Accept payments seamlessly, generate receipts, and manage your finances efficiently from one platform") }}.
            </li>
            <li>
                {{ $t("Automated Invoicing: Save time with automated invoicing") }}. {{ $t("Generate invoices effortlessly, ensuring accuracy and professionalism in your billing process") }}.
            </li>
            <li>
                {{ $t("Global Payment Support: Expand your reach with global payment support") }}. {{ $t("Accept payments in multiple currencies with providers like Stripe, PayPal, PhonePe, and Razorpay") }}.
            </li>
            <li>
                {{ $t("HRMS: Onboard and manage a global workforce") }}. {{ $t("Review employee performance, manage attendance, shifts, timesheets, and leaves") }}.
            </li>
            <li>
                {{ $t("AI-Powered Marketing: Elevate your online branding with our AI generator that crafts catchy images, posts, ads, and blogs") }}.
            </li>
            <li>
                {{ $t("Track Your Finances: Easily track transfers, deposits, and expenses with our comprehensive accounting module") }}.
            </li>
          </ol>
        </div>
      </div>
    </section>
    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 text-uppercase text-center mb-2">
            {{ $t('Sign Up Today!') }}
          </h1>
          <h2 class="large-text-2">{{ $t('Experience seamless business growth with MOM Provider App') }}. {{ $t('Sign up now to get started!') }}</h2>
        </div>
        <div class="col-12 d-flex justify-content-center mt-2">
          <b-link class="custom-design custom-design-black custom-design-fw text-light font-weight-bold" :to="providerRedirect">{{ $t('SIGN UP FOR FREE') }}</b-link>
        </div>
      </div>
    </section>
    <section class="landing_section text-center">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center text-light">
            {{ $t('Pricing') }}
          </h1>
          <p>
            {{ $t('Free forever, No credit card required') }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-11 col-md-12 mx-auto">
          <SubscriptionList 
            :subscriptions="providerSubscriptions"
            @upgradeSubscription="upgradeSubscription"
            :upgradeBtn="`Subscribe`"
          />
        </div>
      </div>
    </section>

    <LandingPageFooter :trasparentFooter="true" />
    <AppFooterBlack :trasparentFooter="true" />
  </div>
</template>

<script>
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import SubscriptionList from '@/views/shared/subscription/SubscriptionList.vue'
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, dateTimeFormat, imgLoad } from '@/utilities'

export default {
  components: {
    LandingPageFooter,
    LandingPageHeader,
    AppFooterBlack,
    SubscriptionList
  },
  directives: {
    lazyBackground: {
      bind: (el, binding) => {
        el.style.backgroundImage = `url('${binding.value}')`;
      }
    }
  },
  data: () => ({
    bg1ImageLoaded: false,
  }),
  computed: {
    ...mapGetters('subscriptionPlan', [
        'isSubscriptionLoaded',
        'providerSubscriptions',
        'isSubscriptionCountryLoaded',
        'countries',
    ]),
    servicesList: {
      get(){
        return [
          {
            title: 'Handyman',
            image: 'handyman.webp',
            url: '/user/store/list/restaurant',
          },
          {
            title: 'Photographer',
            image: 'photographer.webp',
            url: '/user/store/list/grocery',
          },
          {
            title: 'Repair Service',
            image: 'repair-service.webp',
            url: '/user/services/subcategories/list/salon',
          },
          {
            title: 'Laundry',
            image: 'laundry.webp',
            url: '/user/store/list/market',
          },
          {
            title: 'Cleaning Service',
            image: 'cleaning-service.webp',
            url: '/user/store/list/gifts',
          },
          {
            title: 'Salon & Spa',
            image: 'salon-spa.webp',
            url: '/user/services/subcategories/list/repairs',
          },
        ]
      }
    },
    landingItems: {
      get(){
        return [
          {
            img: 'step1.png',
            imgAlt: "Step 1",
            title: this.$t('STEP 1'),
            text: [
              `<strong class="text-light">
                ${this.$t('Register Your Business')}
              </strong>
              - ${this.$t('Enter your business name, logo, address, and other details to create your custom profile link in just 60 seconds')}. ${this.$t('Choose options to schedule bookings for customers, providers, or online directly from your profile')}.`
            ]
          },
          {
            img: 'step2.png',
            imgAlt: "Step 2",
            title: this.$t('STEP 2'),
            text: [
              `<strong class="text-light">
                ${this.$t('Update Your Services')}
              </strong>
              - ${this.$t('Easily update the services you offer, set prices, specify whether you provide services at your location, customer locations, or online')}. ${this.$t('Set your available time slots and allow customers to book instantly')}.`
            ]
          },
          {
            img: 'step3.png',
            imgAlt: "Step 3",
            title: this.$t('STEP 3'),
            text: [
              `<strong class="text-light">
                ${this.$t('Go Online')}
              </strong>
              - ${this.$t('Your profile is now live! Share the link with friends and family via Email, WhatsApp, or Facebook to start accepting bookings')}. ${this.$t('This is where your journey begins')}.`
            ]
          }
        ]
      }
    },
    userRedirect: {
      get(){
        if(window.SuperApp.getters._isUser()){
          return {name: 'user-dashboard'}
        }
        return {name: 'apps-login'}
      }
    },
    shopRedirect: {
      get(){
        if (window.SuperApp.getters._isStore()){
          return { name: 'shop-dashboard' }
        }
        return {name: 'apps-shop-login'}
      }
    },
    providerRedirect: {
      get(){
        if (window.SuperApp.getters._isProvider() && !window.SuperApp.getters._isAgent()){
          return { name: 'provider-requests'}
        }
        else if (window.SuperApp.getters._isProvider() && window.SuperApp.getters._isAgent()){
          return { name: 'provider-agent-dashboard' }
        }
        return {name: 'apps-provider-login'}
      }
    },
  },
  methods: {
    ...mapActions('subscriptionPlan', [
        'getSubscriptionPackages',
        'getCountries',
    ]),
    isEmpty, dateTimeFormat,
    goToService(url) {
      if (url) {
        this.$router.push({
          path: url,
        })
      }
    },
    upgradeSubscription(subscription){
      this.$router.push({
        name: 'apps-provider-registration'
      })
    }
  },
  async mounted() {
    const appUrl = process.env.VUE_APP_FRONT_END_BASE_URL
    const img = require('@/assets/images/bg-images/provider-landing-main.webp')
    const fullImageUrl = `${appUrl}${img}`
    imgLoad(fullImageUrl).then(r => {
      this.bg1ImageLoaded = true
    })

    if (!localStorage.getItem('providerSelectedCountryId')) {
      try {
        const ipInfo = await fetch('https://api.ipregistry.co/?key=dsnrdu52t7ii07ny')
        if(!isEmpty(ipInfo?.location)){
            if(!this.isSubscriptionCountryLoaded){
              await this.getCountries().then(data => {
                if (data._statusCode === 200) {
                    const countries = data.responseData
                    const isCountryExist = countries.find(x => x.country_name === ipInfo.location.country.name)
                    if (isCountryExist) {
                      localStorage.setItem('providerSelectedCountryId', isCountryExist.id)
                    }
                }
              })
            }else{
              const countries = this.countries
              const isCountryExist = countries.find(x => x.country_name === ipInfo.location.country.name)
              if (isCountryExist) {
                localStorage.setItem('providerSelectedCountryId', isCountryExist.id)
              }
            }
          }
      } catch (error) {
        console.log(error)
      }
    }
    if (!this.isSubscriptionLoaded) {
        this.getSubscriptionPackages()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.bg-1 {
  background-color: #040612;
  background-image: url('~@/assets/images/bg-images/provider-landing-main.webp');
  padding-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';
</style>
